<template>

	<!-- Conversations Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }">
		<template #title>
			<h6 class="font-semibold m-0">Listado de ordenes</h6>
		</template>
		<a-list
			class="conversations-list"
			item-layout="horizontal"
			:split="false"
			:data-source="data"
		>
			<a-list-item slot="renderItem" slot-scope="item">
             
                    <a-button slot="actions" type="link" @click="goTo(item.id)">
                        Ver
                    </a-button>
             
				<a-list-item-meta
					:title="item.productName"
					:description="item.pricing"
				>
					
				</a-list-item-meta>
			</a-list-item>
		</a-list>
	</a-card>
	<!-- / Conversations Card -->

</template>

<script>

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
			}
		},
        methods:{
            goTo(val){
                this.$router.push(`/ordersdetail/${val}`);
            }
        }
	})

</script>
<style lang="scss">
.conversations-list {
  overflow-y: scroll;
  max-height: 350px; /* Ajusta la altura máxima según tus necesidades */
}
</style>