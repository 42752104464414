<template>

	<!-- Profile Information Card -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingRight: 0,}">
		<template #title>
			<h6 class="font-semibold m-0">Información de perfil</h6>
		</template>
		<a-button v-if="!showInput"type="link" slot="extra" @click="editInfo()">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827"/>
				<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827"/>
			</svg>
		</a-button>
		<a-button v-if="showInput" type="link" slot="extra" @click="editInfo()">
		    <a-icon type="search" theme="outlined" />
		</a-button>
		<p class="text-dark">
			Al crear una orden esta es la información que se recibiremos para contactarte, asegura que esten correcto los datos
		</p>
		<hr class="my-25">
		<a-descriptions  :column="1">
			<a-descriptions-item label="Nombre">
				  <p v-if="!showInput">{{name}}</p>
				  <a-input v-if="showInput" v-model="user.name" type="text" placeholder="Número télefonico"></a-input>
			</a-descriptions-item>
			<a-descriptions-item label="Número telefonico">
				<p v-if="!showInput">{{phone}} </p>
				<a-input v-if="showInput" v-model="user.phone" type="text" placeholder="Número télefonico"></a-input>
			</a-descriptions-item>
			<a-descriptions-item label="Correo">
				{{email}}
			</a-descriptions-item>
			<a-descriptions-item label="Dirección">
			   <p v-if="!showInput">	{{address}} </p>
				<a-input v-if="showInput" v-model="user.address"type="text" placeholder="Dirección"></a-input>
			</a-descriptions-item>
			<!--<a-descriptions-item label="Social">
				<a href="#" class="mx-5 px-5">
					<a-icon type="twitter" style="color: #3EA1EC;" />
				</a>
				<a href="#" class="mx-5 px-5">
					<a-icon type="facebook" style="color: #344E86" />
				</a>
				<a href="#" class="mx-5 px-5">
					<a-icon type="instagram" style="color: #E1306C" />
				</a>
			</a-descriptions-item>-->
		</a-descriptions>
			<a-button v-if="showInput" type="primary" @click="update" block target="_blank">
						ACTUALIZAR USUARIO
			</a-button>
	</a-card>
	<!-- / Profile Information Card -->

</template>

<script>
import { isLogin, getUserById } from '@/services/auth/auth';
	export default ({
		data() {
			return {
				user:{
					name:null,
					email:null,
					address:null,
					phone:null
				},
				showInput:false
			}
		},
		props: {
			
			name: {
				type: String,
				default: "",
			},
            address: {
				type: String,
				default: "",
			},
			phone: {
				type: String,
				default: "",
			},
			email: {
				type: String,
				default: "",
			},
			
		},
		mounted(){
			
		},
		methods:{
			 editInfo(){
				this.user.name=this.name
				this.user.phone=this.phone
				this.user.address=this.address
				this.user.email=this.email	
				this.showInput=!this.showInput
			 },
			 update(){
				
				this.$emit('updateUser', this.user)
			 }
		}
	
	})

</script>