<!-- 
	This is the user profile page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- Header Background Image -->
		<div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg')"></div>
		<!-- / Header Background Image -->

		<!-- User Profile Card -->
	
		<!-- User Profile Card -->
		<br>
		<a-row type="flex" :gutter="24">

			<!-- Platform Settings Column -->
			
			<!-- / Platform Settings Column -->

			<!-- Profile Information Column -->
			<a-col :span="24" :md="12" class="mb-24">

				<!-- Profile Information Card -->
				<CardProfileInformation
				:name="user.name"
				:address="user.address"
				:phone="user.phone"
				:email="user.email"
				@updateUser="updateUser"
				></CardProfileInformation>
				<!-- / Profile Information Card -->

			</a-col>
			<!-- / Profile Information Column -->
			
			<!-- Conversations Column -->
			<a-col :span="24" :md="12" class="mb-24">
			
				<!-- Conversations Card -->
				<CardOrdersResume
					:data="orders"
				></CardOrdersResume>
				<!-- / Conversations Card -->

			</a-col>
			<!-- / Conversations Column -->

		</a-row>
		


	</div>
</template>

<script>

import CardPlatformSettings from "../components/Cards/CardPlatformSettings"
import CardProfileInformation from "../components/Cards/CardProfileInformation"
import CardOrdersResume from "../components/Cards/CardOrdersResume"
import CardProject from "../components/Cards/CardProject"
import { isLogin, getUserById, updateUser } from '@/services/auth/auth';
import { getOrderById } from '@/services/orders/orders';
// Conversation's list data.
const orders = [] ;

// Project cards data
const projects = [
	{
		id: 1,
		title: "Modern",
		content: "As Uber works through a huge amount of internal management turmoil.",
		cover: "images/home-decor-3.jpeg",
		team: [
			"images/face-1.jpg",
			"images/face-4.jpg",
			"images/face-2.jpg",
			"images/face-3.jpg",
		],
	},
	{
		id: 2,
		title: "Scandinavian",
		content: "Music is something that every person has his or her own specific opinion about.",
		cover: "images/home-decor-2.jpeg",
		team: [
			"images/face-1.jpg",
			"images/face-4.jpg",
			"images/face-2.jpg",
			"images/face-3.jpg",
		],
	},
	{
		id: 3,
		title: "Minimalist",
		content: "Different people have different taste, and various types of music, Zimbali Resort.",
		cover: "images/home-decor-1.jpeg",
		team: [
			"images/face-1.jpg",
			"images/face-4.jpg",
			"images/face-2.jpg",
			"images/face-3.jpg",
		],
	},
] ;

export default ({
	components: {
		CardPlatformSettings,
		CardProfileInformation,
		CardOrdersResume,
		CardProject,
	},
	data() {
		return {
			// Active button for the "User Profile" card's radio button group.
			profileHeaderBtns: 'overview',

			// Associating Conversation's list data with its corresponding property.
			orders,

			// Project cards data
			projects,
			user:{
				id:null,
				name:null,
				email:null,
				phone:null,
				address:null
			}
		}
	},
	created(){
		this.getUser()
	},
	methods:{
		 getUser(){
		
			   isLogin((user)=>{
				
					if(user){
						getUserById((data)=>{
							
						getOrderById((data)=>{
							this.orders=data
						},user.uid)
						
							this.user.id=data.id
							this.user.name=data.name
							this.user.email=data.email
							this.user.address=data.address
							this.user.phone=data.phone
						},user.uid)
					}
			   })	
		},
		updateUser(val){
			updateUser(this.user.id,val)
		}
	}
})

</script>

<style lang="scss">
</style>